import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

const PageNav = ({ pageContext }) => {
  let nextPagePath=''; let previousPagePath = '';
  if(pageContext.NextPath!=null) {
     nextPagePath =pageContext.NextPath.path.alias; 
   }
  if(pageContext.PrevPath!=null) {
     previousPagePath =pageContext.PrevPath.path.alias;     
  } 
    return (
      <React.Fragment>
        {previousPagePath && (
          <div className='col-6'><Link to={previousPagePath} className="float-left nextContent">Previous</Link></div>
        )}
        {nextPagePath && (
          <div  className='col-6'><Link to={nextPagePath} className="float-right nextContent">Next</Link></div>
                                 
        )}
       </React.Fragment>
    );
  };
   
  PageNav.propTypes = {
    pageContext: PropTypes.object.isRequired,
  };
   
  export default PageNav;