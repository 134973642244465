import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Masonry from "react-masonry-css"
import {
  getCookie,
  getBookMarkList,
  getUser,
  authenticate,
  isAuth,
  updateUser,
} from "../components/helpers"
import axios from "axios"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import starIcon from "../images/star.png"
import shareIcon from "../images/share.png"
import activeStarIcon from "../images/star-active.png"
import PageNav from "../components/pageNav"
import ArticlePreview from "../components/articlePreview"
import { ToastContainer, toast } from "react-toastify"
import ReactHtmlParser from "react-html-parser"

import {
  FacebookShareButton,
  // InstapaperShareButton,
  // LineShareButton,
  // LinkedinShareButton,
  // LivejournalShareButton,
  // MailruShareButton,
  // OKShareButton,
  PinterestShareButton,
  // PocketShareButton,
  // RedditShareButton,
  // TelegramShareButton,
  // TumblrShareButton,
  TwitterShareButton,
  // ViberShareButton,
  // VKShareButton,
  WhatsappShareButton,
  // WorkplaceShareButton,
} from "react-share"

// import {
//     FacebookShareCount,
//     OKShareCount,
//     PinterestShareCount,
//     RedditShareCount,
//     TumblrShareCount,
//     VKShareCount,
// } from "react-share";

import {
  FacebookIcon,
  PinterestIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share"

const breakpointColumnsObj = {
  default: 4,
  500: 1,
}

// import Share from '../components/Share';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Article = ({ data, pageContext }) => {
  const post = data.nodeArticle
  const similarArticles = data.similar.nodes
  //console.log("pageContext", pageContext);
  //console.log("similar", similarArticles);
  //console.log("similarID", similarArticles.id);
  // const labels = post.field_label.split(",")
  // console.log(labels)
  // console.log("getBookMarkList - Article Page", getBookMarkList())

  let seoTitle
  let seoDescription
  let seoAbstract
  let seoKeywords
  let labelTag
  if (post.field_label == null) {
    labelTag = ""
  } else {
    labelTag = post.field_label
  }
  if (post.field_meta != null) {
    seoTitle = post.field_meta.title
    seoDescription = post.field_meta.description
    seoAbstract = post.field_meta.description
    seoKeywords = post.field_meta.keywords
  } else {
    seoTitle = post.title
    seoDescription = post.body.summary
    seoAbstract = post.body.summary
    seoKeywords = "Kalyan Shastra " + post.relationships.field_tags[0].name
  }
  let article_body_elements = new ReactHtmlParser(post.body.processed, {
    transform: function transform(node) {
      if (node.type === "tag" && node.name === "img") {
        let uuid = node.attribs["data-entity-uuid"]
        let i = 0
        for (i = 0; i < data.allFileFile.edges.length; i++) {
          if (
            data.allFileFile.edges[i].node.drupal_id === uuid &&
            data.allFileFile.edges[i].node.localFile
          ) {
            return (
              <Img
                fluid={
                  data.allFileFile.edges[i].node.localFile.childImageSharp.fluid
                }
              />
            )
          } else {
            //   console.log('data.allFileFile.edges[i].node.uuid', data.allFileFile.edges[i].node.uuid);
            //   console.log('uuid', uuid);
          }
        }
      }
      return undefined
    },
  })
  const crumbs = post.relationships.field_tags;
  const [values, setValues] = useState({
    view: false,
    // isMarked: (articleArray.indexOf(post.drupal_id) != -1 && isAuth()) ? true  : false
    isMarked: false,
  })

  const { view, isMarked } = values

  useEffect(() => {
    let articleArray = getBookMarkList()
    setValues({
      ...values,
      isMarked:
        articleArray.indexOf(post.drupal_id) != -1 && isAuth() ? true : false,
    })
    console.log("mount it!", isMarked)
  }, []) // passing an empty array as second argument triggers the callback in useEffect only after the initial render thus replicating `componentDidMount` lifecycle behaviour

  // console.log('page-load',(articleArray.indexOf(post.drupal_id) != -1 && isAuth()) ? true  : false);

  function showHideShare() {
    //setView(!view);
    setValues({ ...values, view: !view })
  }

  const pageImage =
    post.relationships.field_image.localFile.childImageSharp.fluid
  const imageUrl = post.relationships.field_image.localFile.url
  const shareUrl = "https://www.kalyanshastra.com/"
  const pinUrl =
    "https://in.pinterest.com/pin/create/button/?description=" +
    post.title +
    "&media=" +
    imageUrl +
    "&url=" +
    shareUrl +
    post.path.alias

  function addScraBook() {
    // alert(isMarked);
    // setValues({ ...values, isMarked: !isMarked });
    // alert(isMarked);
    const token = getCookie("token")

    if (!isAuth()) {
      toast.error("Login to add an Article to Scrapbook")
    } else {
      axios({
        method: "POST",
        url: "https://www.kalyanshastra.com/api/scrapbook",
        headers: {
          token: token,
        },
        data: {
          emailId: getUser().emailId,
          articleId: post.drupal_id,
        },
      })
        .then(response => {
          console.log("response", response)
          if (response.data.status == "1") {
            toast.success(post.title + " " + " - Added to Scrapbook")
          } else {
            toast.error(post.title + " " + "Removed from Scrapbook")
          }

          setValues({ ...values, isMarked: !isMarked })
        })
        .catch(error => {
          console.log("Scrapbook", error)
          // toast.error(error);
        })
    }
  }

  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        abstract={seoAbstract}
        keywords={seoKeywords}
      />
      <section id="traditionalContent">
        <div className="row backNavGreen">
          <div className="col-md-12" id="navCrumb">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="row" id="breadCrumb">
                    <div className="col-md-12">
                      <span>
                        <Link to="/"> Home </Link>
                        &nbsp;/&nbsp;
                        {crumbs
                          .sort((a, b) => {
                            if (a.path.pid < b.path.pid) return -1
                            else if (a.path.pid > b.path.pid) return 1
                            else return 0
                          })
                          .map((crumb, index, crumbs) =>
                            crumbs[crumbs.length - 1] == crumbs[index] ? (
                              <span className="capitalise">
                                <Link to={crumb.path.alias}>{crumb.name}</Link>
                              </span>
                            ) : (
                              <span className="capitalise">
                                <Link to={crumb.path.alias}>{crumb.name}</Link>
                                &nbsp;/&nbsp;
                              </span>
                            )
                          )}
                        {/* <Link to={post.relationships.field_tags[0].path.alias}>
                          <span className="capitalise">
                            {post.relationships.field_tags[0].name}
                          </span>
                        </Link> */}
                      </span>
                    </div>
                  </div>
                  <div className="row" id="headCrumb">
                    <div className="col-md-12">
                      <h4 className="capitalise">
                        {post.relationships.field_tags[0].name}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" id="shareIcons">
          <div className="container">
            <div className="float-right mr-md share-icon-container">
              <span>
                <img src={shareIcon} alt="share" onClick={showHideShare} />
              </span>
              {view === true && (
                <div className="share-container">
                  <div className="share-content">
                    <ul>
                      <li>
                        <FacebookShareButton
                          url={
                            "https://www.kalyanshastra.com/" + post.path.alias
                          }
                        >
                          <FacebookIcon
                            size={30}
                            round={true}
                            openShareDialogOnClick={true}
                          />
                        </FacebookShareButton>
                      </li>
                      <li>
                        <TwitterShareButton
                          url={
                            "https://www.kalyanshastra.com/" + post.path.alias
                          }
                        >
                          <TwitterIcon
                            size={30}
                            round={true}
                            openShareDialogOnClick={true}
                          />
                        </TwitterShareButton>
                      </li>
                      <li>
                        <WhatsappShareButton
                          url={
                            "https://www.kalyanshastra.com/" + post.path.alias
                          }
                        >
                          <WhatsappIcon
                            size={30}
                            round={true}
                            openShareDialogOnClick={true}
                          />
                        </WhatsappShareButton>
                      </li>
                      <li>
                        <a href={pinUrl} target="_blank">
                          <PinterestIcon
                            size={30}
                            round={true}
                            openShareDialogOnClick={true}
                          />
                        </a>
                      </li>
                    </ul>
                  </div>{" "}
                </div>
              )}
            </div>

            <div className="float-right  page-icon">
              {/* <span  className="scrapbook-icon" onClick={addScraBook}>
                {" "}
                {"  "}  {"  "}  <img src={activeStarIcon} alt="" /> {"  "}
              </span> */}

              {(() => {
                if (isMarked) {
                  return (
                    <span className="scrapbook-icon" onClick={addScraBook}>
                      {" "}
                      <img src={activeStarIcon} alt="" /> {"  "}
                    </span>
                  )
                } else {
                  return (
                    <span className="scrapbook-icon" onClick={addScraBook}>
                      {"  "} <img src={starIcon} alt="" /> {"  "}
                    </span>
                  )
                }
              })()}
            </div>
          </div>
        </div>
        <div className="" id="traditionalContent">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-5">
                <Img
                  className="image-align"
                  align="left"
                  width="300"
                  fluid={pageImage}
                  alt={post.field_image.alt}
                  title={post.field_image.alt}
                />
              </div>
              <div className="col-md-7">
                <h3 className="mb-4 contentHead"> {post.title} </h3>

                <div className="content article-content">
                  {article_body_elements}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 my-3">
                {(() => {
                  if (post.field_label !== null) {
                    return (
                      <div>
                        <div className="labelHead">Tags</div>
                        <p className="mb-0">
                          {labelTag
                            .split(",")
                            .map((label, index, labelTag) =>
                              labelTag[index] !== " " && label.length > 2 ? (
                                <span className="labelTag">{label}</span>
                              ) : (
                                <span className="labelTag d-none">{label}</span>
                              )
                            )}
                        </p>
                      </div>
                    )
                  }
                })()}
              </div>
            </div>
            <div className="row justify-content-end" id="nextPage">
              <PageNav pageContext={pageContext} />
            </div>
          </div>
        </div>
      </section>

      <section id="recommendations">
        <div className="container">
          <div className="row" id="recommendationsHeading">
            <div className="col-12">
              <h3>Similar Recommendations</h3>
            </div>
          </div>

          <div className="col-md-12">
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {(() => {
                if (similarArticles.length > 0) {
                  return similarArticles.map(article => (
                    <div className="grid-item">
                      {
                        <div>
                          <ArticlePreview
                            key={article.id}
                            articleId={article.drupal_id}
                            title={article.title}
                            path={article.path.alias}
                            image={
                              article.relationships.field_image.localFile
                                .childImageSharp.fluid
                            }
                            tag={article.relationships.field_tags[0].name}
                            alt={article.field_image.alt}
                            summary={
                              article.body.summary
                                ? article.body.summary
                                : article.body.processed.substring(0, 300)
                            }
                            // isBookMarked={
                            //   (articleArray.indexOf(article.drupal_id) != -1 && isAuth()) ? true  : false
                            // }
                          />
                          <div className="clearFix"></div>
                        </div>
                      }
                    </div>
                  ))
                } else {
                  return (
                    <div className="noArticles">
                      <p>No Articles Available for Recommendations</p>
                    </div>
                  )
                }
              })()}
            </Masonry>
            <ToastContainer />
          </div>
        </div>
        <div className="clearFix"></div>
      </section>
    </Layout>
  )
}

Article.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query($ArticleId: String, $ArticleIdTagId: String) {
    nodeArticle(id: { eq: $ArticleId }) {
      id
      drupal_id
      title
      body {
        processed
      }
      path {
        alias
      }
      field_image {
        alt
      }
      relationships {
        field_tags {
          ... on taxonomy_term__kalyan_shastra_categories {
            id
            name
            weight
            path {
              alias
              pid
            }
          }
        }
        field_image {
          localFile {
            url
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      field_label
      field_meta {
        title
        description
        keywords
      }
    }

    allFileFile {
      edges {
        node {
          drupal_id
          localFile {
            childImageSharp {
              fluid(maxWidth: 1024) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    similar: allNodeArticle(
      limit: 4
      filter: {
        relationships: {
          field_tags: { elemMatch: { id: { eq: $ArticleIdTagId } } }
        }
        id: { ne: $ArticleId }
      }
    ) {
      nodes {
        id
        drupal_id
        title
        body {
          processed
          summary
        }
        path {
          alias
        }
        field_image {
          alt
        }
        relationships {
          field_tags {
            ... on taxonomy_term__kalyan_shastra_categories {
              id
              name
            }
          }
          field_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Article
